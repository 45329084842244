@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  src: url('Inter-Black.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: url('Inter-ExtraBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: url('Inter-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url('Inter-SemiBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url('Inter-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'GT Flexa Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('GT-Flexa-Condensed-Bold.woff2') format('woff2');
  font-display: swap;
}

body {
  font-family: Inter, 'GT Flexa Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font-primary);
}

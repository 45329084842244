@import 'colors';

:root,
:host {
  --black: #{$black};
  --gray-100: #{$gray-100};
  --gray-95: #{$gray-95};
  --gray-90: #{$gray-90};
  --gray-80: #{$gray-80};
  --gray-70: #{$gray-70};
  --gray-60: #{$gray-60};
  --gray-50: #{$gray-50};
  --gray-40: #{$gray-40};
  --gray-30: #{$gray-30};
  --gray-20: #{$gray-20};
  --gray-10: #{$gray-10};
  --gray-05: #{$gray-05};
  --white: #{$white};

  // blue
  --blue-90: #{$blue-90};
  --blue-80: #{$blue-80};
  --blue-70: #{$blue-70};
  --blue-60: #{$blue-60};
  --blue-50: #{$blue-50};
  --blue-40: #{$blue-40};
  --blue-30: #{$blue-30};
  --blue-20: #{$blue-20};
  --blue-10: #{$blue-10};

  // green
  --green-90: #{$green-90};
  --green-80: #{$green-80};
  --green-60: #{$green-60};
  --green-50: #{$green-50};
  --green-40: #{$green-40};
  --green-30: #{$green-30};
  --green-20: #{$green-20};
  --green-10: #{$green-10};

  // red
  --red-90: #{$red-90};
  --red-80: #{$red-80};
  --red-60: #{$red-60};
  --red-50: #{$red-50};
  --red-30: #{$red-30};
  --red-20: #{$red-20};
  --red-10: #{$red-10};

  // yellow
  --yellow-90: #{$yellow-90};
  --yellow-80: #{$yellow-80};
  --yellow-70: #{$yellow-70};
  --yellow-60: #{$yellow-60};
  --yellow-50: #{$yellow-50};
  --yellow-40: #{$yellow-40};
  --yellow-30: #{$yellow-30};
}

@import '~normalize.css';

html {
  box-sizing: border-box;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: var(--fill-01);
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
}

img {
  max-width: 100%;
}

button {
  background-color: transparent;
  border: none;

  &:active,
  &:focus {
    outline: none;
  }
}

input {
  filter: none;

  &:active,
  &:focus {
    outline: none;
  }
}

#modal-root {
  position: relative;
  z-index: var(--modal-z-index);
}

svg {
  flex-shrink: 0;
}

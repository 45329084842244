@keyframes expandProgressBar {
  0% {
    width: 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) translateZ(0);
  }

  100% {
    transform: rotate(360deg) translateZ(0);
  }
}

@keyframes slideIn {
  0% {
    right: -100px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

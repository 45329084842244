@import 'dark-theme';
@import 'light-theme';

:root,
:host {
  // colors
  --black: #{$blue-90};
  --white: #{$white};
  --positive: #{$light-positive};
  --negative: #{$light-negative};

  // fonts
  --font-primary: #{$light-font-primary};
  --font-secondary: #{$light-font-secondary};
  --font-tertiary: #{$light-font-tertiary};
  --font-disabled: #{$light-font-disabled};
  --font-inverted: #{$light-font-inverted};
  --font-interactive: #{$light-font-interactive};
  --font-negative: #{$light-font-negative};
  --font-positive: #{$light-font-positive};
  --font-white: #{$light-font-white};
  --font-black: #{$light-font-black};

  // fills
  --fill-01: #{$light-fill-01};
  --fill-02: #{$light-fill-02};
  --fill-03: #{$light-fill-03};
  --fill-04: #{$light-fill-04};
  --fill-05: #{$light-fill-05};
  --fill-06: #{$light-fill-06};
  --fill-07: #{$light-fill-07};
  --fill-interactive-loud: #{$light-fill-interactive-loud};
  --fill-interactive-loud-hover: #{$light-fill-interactive-loud-hover};
  --fill-interactive-silent: #{$light-fill-interactive-silent};
  --fill-positive-loud: #{$light-fill-positive-loud};
  --fill-positive-silent: #{$light-fill-positive-silent};
  --fill-negative-loud: #{$light-fill-negative-loud};
  --fill-negative-silent: #{$light-fill-negative-silent};
  --fill-attention-loud: #{$light-fill-attention-loud};

  // outlines
  --outline-extra-silent: #{$light-outline-extra-silent};
  --outline-silent: #{$light-outline-silent};
  --outline-medium: #{$light-outline-medium};
  --outline-loud: #{$light-outline-loud};
  --outline-extra-loud: #{$light-outline-extra-loud};
  --outline-interactive-loud: #{$light-outline-interactive-loud};
  --outline-interactive-silent: #{$blue-30};
  --outline-positive-loud: #{$light-outline-positive-loud};
  --outline-positive-silent: #{$light-outline-positive-silent};
  --outline-negative-loud: #{$light-outline-negative-loud};
  --outline-negative-silent: #{$light-outline-negative-silent};
  --outline-overlay: #{$light-outline-overlay};

  // opacity
  --opacity-overlay: #{$light-opacity-overlay};
  --opacity-primary-8: #{$light-opacity-primary-8};
  --opacity-primary-12: #{$light-opacity-primary-12};
  --opacity-primary-24: #{$light-opacity-primary-24};
  --opacity-primary-32: #{$light-opacity-primary-32};
  --opacity-primary-48: #{$light-opacity-primary-48};
  --opacity-inverted-primary-8: #{$light-opacity-inverted-primary-8};
  --opacity-inverted-primary-12: #{$light-opacity-inverted-primary-12};
  --opacity-inverted-primary-24: #{$light-opacity-inverted-primary-24};
  --opacity-inverted-primary-32: #{$light-opacity-inverted-primary-32};
  --opacity-inverted-primary-48: #{$light-opacity-inverted-primary-48};

  // decorative
  --decorative-green: #{$light-decorative-green};
  --decorative-orange: #{$light-decorative-orange};
  --decorative-red: #{$light-decorative-red};
  --decorative-pink: #{$light-decorative-pink};
  --decorative-violet: #{$light-decorative-violet};

  // shadows
  --shadow-s-silent: #{$light-shadow-s-silent};
  --shadow-s-medium: #{$light-shadow-s-medium};
  --shadow-s-loud: #{$light-shadow-s-loud};
  --shadow-m-silent-top: #{$light-shadow-m-silent-top};
  --shadow-m-silent: #{$light-shadow-m-silent};
  --shadow-m-medium: #{$light-shadow-m-medium};
  --shadow-m-loud: #{$light-shadow-m-loud};
  --shadow-xl-silent: #{$light-shadow-xl-silent};
  --shadow-xl-medium: #{$light-shadow-xl-medium};
  --shadow-xl-loud: #{$light-shadow-xl-loud};

  // tabs
  --switch-fill: var(--fill-01);

  // blur
  --fill-blur: #{$light-fill-blur};
  --filter-blur: #{$light-filter-blur};

  // animation-timings
  --animation-time-medium: 225ms;
  --animation-time-long: 400ms;

  // fonts
  --font-inter: Inter, 'GT Flexa Condensed', sans-serif;
  --font-gt-flexa: 'GT Flexa Condensed', Inter, sans-serif;
}

body.dark {
  --positive: #{$dark-positive};
  --negative: #{$dark-negative};

  // fonts
  --font-primary: #{$dark-font-primary};
  --font-secondary: #{$dark-font-secondary};
  --font-tertiary: #{$dark-font-tertiary};
  --font-disabled: #{$dark-font-disabled};
  --font-inverted: #{$dark-font-inverted};
  --font-interactive: #{$dark-font-interactive};
  --font-white: #{$dark-font-white};
  --font-black: #{$dark-font-black};
  --font-negative: #{$dark-font-negative};
  --font-positive: #{$dark-font-positive};

  // fills
  --fill-01: #{$dark-fill-01};
  --fill-02: #{$dark-fill-02};
  --fill-03: #{$dark-fill-03};
  --fill-04: #{$dark-fill-04};
  --fill-05: #{$dark-fill-05};
  --fill-06: #{$dark-fill-06};
  --fill-07: #{$dark-fill-07};
  --fill-interactive-loud: #{$dark-fill-interactive-loud};
  --fill-interactive-loud-hover: #{$dark-fill-interactive-loud-hover};
  --fill-interactive-silent: #{$dark-fill-interactive-silent};
  --fill-positive-loud: #{$dark-fill-positive-loud};
  --fill-positive-silent: #{$dark-fill-positive-silent};
  --fill-negative-loud: #{$dark-fill-negative-loud};
  --fill-negative-silent: #{$dark-fill-negative-silent};
  --fill-attention-loud: #{$dark-fill-attention-loud};

  // outlines
  --outline-extra-silent: #{$dark-outline-extra-silent};
  --outline-silent: #{$dark-outline-silent};
  --outline-medium: #{$dark-outline-medium};
  --outline-loud: #{$dark-outline-loud};
  --outline-extra-loud: #{$dark-outline-extra-loud};
  --outline-interactive-loud: #{$dark-outline-interactive-loud};
  --outline-interactive-silent: #{$blue-20};
  --outline-positive-loud: #{$dark-outline-positive-loud};
  --outline-positive-silent: #{$dark-outline-positive-silent};
  --outline-negative-loud: #{$dark-outline-negative-loud};
  --outline-negative-silent: #{$dark-outline-negative-silent};
  --outline-overlay: #{$dark-outline-overlay};

  // opacity
  --opacity-overlay: #{$dark-opacity-overlay};
  --opacity-primary-8: #{$dark-opacity-primary-8};
  --opacity-primary-12: #{$dark-opacity-primary-12};
  --opacity-primary-24: #{$dark-opacity-primary-24};
  --opacity-primary-32: #{$dark-opacity-primary-32};
  --opacity-primary-48: #{$dark-opacity-primary-48};
  --opacity-inverted-primary-8: #{$dark-opacity-inverted-primary-8};
  --opacity-inverted-primary-12: #{$dark-opacity-inverted-primary-12};
  --opacity-inverted-primary-24: #{$dark-opacity-inverted-primary-24};
  --opacity-inverted-primary-32: #{$dark-opacity-inverted-primary-32};
  --opacity-inverted-primary-48: #{$dark-opacity-inverted-primary-48};

  // decorative
  --decorative-green: #{$dark-decorative-green};
  --decorative-orange: #{$dark-decorative-orange};
  --decorative-red: #{$dark-decorative-red};
  --decorative-pink: #{$dark-decorative-pink};
  --decorative-violet: #{$dark-decorative-violet};

  // shadows
  --shadow-s-silent: #{$dark-shadow-s-silent};
  --shadow-s-medium: #{$dark-shadow-s-medium};
  --shadow-s-loud: #{$dark-shadow-s-loud};
  --shadow-m-silent-top: #{$dark-shadow-m-silent-top};
  --shadow-m-silent: #{$dark-shadow-m-silent};
  --shadow-m-medium: #{$dark-shadow-m-medium};
  --shadow-m-loud: #{$dark-shadow-m-loud};
  --shadow-xl-silent: #{$dark-shadow-xl-silent};
  --shadow-xl-medium: #{$dark-shadow-xl-medium};
  --shadow-xl-loud: #{$dark-shadow-xl-loud};

  // tabs
  --switch-fill: var(--fill-interactive-loud);

  // blur
  --fill-blur: #{$dark-fill-blur};
  --filter-blur: #{$dark-filter-blur};
}

@import '../colors';

:root,
:host {
  // font
  --dark-font-primary: var(--white);
  --dark-font-secondary: var(--gray-50);
  --dark-font-tertiary: var(--gray-70);
  --dark-font-disabled: var(--gray-80);
  --dark-font-inverted: var(--black);
  --dark-font-white: var(--white);
  --dark-font-black: var(--black);
  --dark-font-interactive: var(--blue-60);
  --dark-font-positive: var(--green-50);
  --dark-font-negative: var(--red-50);

  // fills
  --dark-fill-01: var(--black);
  --dark-fill-02: var(--gray-100);
  --dark-fill-03: var(--gray-90);
  --dark-fill-04: var(--gray-90);
  --dark-fill-05: var(--gray-80);
  --dark-fill-06: var(--gray-60);
  --dark-fill-07: var(--white);
  --dark-fill-interactive-loud: var(--blue-60);
  --dark-fill-interactive-loud-hover: var(--blue-50);
  --dark-fill-interactive-silent: var(--blue-90);
  --dark-fill-interactive-silent-hover: var(--blue-80);
  --dark-fill-positive-loud: var(--green-50);
  --dark-fill-positive-silent: var(--green-90);
  --dark-fill-negative-loud: var(--red-50);
  --dark-fill-negative-silent: var(--red-90);
  --dark-fill-attention-loud: var(--yellow-60);

  // outlines
  --dark-outline-extra-silent: var(--gray-100);
  --dark-outline-silent: var(--gray-95);
  --dark-outline-medium: var(--gray-90);
  --dark-outline-loud: var(--gray-80);
  --dark-outline-extra-loud: var(--white);
  --dark-outline-interactive-loud: var(--blue-50);
  --dark-outline-interactive-silent: var(--blue-80);
  --dark-outline-positive-loud: var(--green-50);
  --dark-outline-positive-silent: var(--green-80);
  --dark-outline-negative-loud: var(--red-50);
  --dark-outline-negative-silent: var(--red-80);
  --dark-outline-overlay: #{rgba($white, .06)};

  // opacity
  --dark-opacity-overlay: #{rgba($gray-80, .72)};
  --dark-opacity-primary-8: #{rgba($white, .08)};
  --dark-opacity-primary-12: #{rgba($white, .12)};
  --dark-opacity-primary-24: #{rgba($white, .24)};
  --dark-opacity-primary-32: #{rgba($white, .32)};
  --dark-opacity-primary-48: #{rgba($white, .48)};
  --dark-opacity-inverted-primary-8: #{rgba($black, .08)};
  --dark-opacity-inverted-primary-12: #{rgba($black, .12)};
  --dark-opacity-inverted-primary-24: #{rgba($black, .24)};
  --dark-opacity-inverted-primary-32: #{rgba($black, .32)};
  --dark-opacity-inverted-primary-48: #{rgba($black, .48)};

  // decorative
  --dark-decorative-green: linear-gradient(90deg, #00b09b 0%, #96c93d 100%);
  --dark-decorative-orange: linear-gradient(90deg, #ee5682 0%, #e88b5a 100%);
  --dark-decorative-red: linear-gradient(90deg, #cb356b 0%, #bd3f32 100%);
  --dark-decorative-pink: linear-gradient(90deg, #ea1ee4 0%, #961ede 100%);
  --dark-decorative-violet: linear-gradient(270deg, #a41edf 0%, #3e1dd9 100%);

  // shadows
  --dark-shadow-s-silent: 0 .5px 0 rgb(56 56 56 / .64);
  --dark-shadow-s-medium: 0 .5px 0 rgb(56 56 56 / .76);
  --dark-shadow-s-loud: 0 .5px 0 rgb(56 56 56 / .9);
  --dark-shadow-m-silent-top: 0 -2px 6px rgb(0 0 0 / .1);
  --dark-shadow-m-silent: 0 2px 6px rgb(0 0 0 / .4);
  --dark-shadow-m-medium: 0 2px 6px rgb(0 0 0 / .72);
  --dark-shadow-m-loud: 0 2px 6px rgb(0 0 0 / .88);
  --dark-shadow-xl-silent: 0 8px 24px rgb(0 0 0 / .56);
  --dark-shadow-xl-medium: 0 8px 24px rgb(0 0 0 / .72);
  --dark-shadow-xl-loud: 0 8px 24px rgb(0 0 0 / .88);

  // filters
  --dark-fill-blur: var(--dark-opacity-primary-48);
  --dark-filter-blur: blur(80px);
}

:root {
  --nav-z-index: 9999998;
  --transaction-history-button-filters-z-index: 2;
  --transaction-history-button-filters-overlay-z-index: 1;
  --transaction-history-status-tooltip-z-index: 3;
  --transaction-history-mobile-navigation-z-index: 15;
  --shops-search-bar-z-index: 8;
  --toaster-z-index: 9999999;
  --modal-z-index: 9999999;
  --shops-filters-header-z-index: 9;
  --large-product-grid-with-filters: 5;
  --large-product-favorite-indicator: 2;
}

@import '../colors';

:root,
:host {
  // fonts
  --light-font-primary: var(--gray-100);
  --light-font-secondary: var(--gray-80);
  --light-font-tertiary: var(--gray-60);
  --light-font-disabled: var(--gray-50);
  --light-font-inverted: var(--white);
  --light-font-white: var(--white);
  --light-font-black: var(--black);
  --light-font-interactive: var(--blue-60);
  --light-font-positive: var(--green-50);
  --light-font-negative: var(--red-50);

  // fills
  --light-fill-01: var(--white);
  --light-fill-02: var(--gray-05);
  --light-fill-03: var(--gray-20);
  --light-fill-04: var(--gray-30);
  --light-fill-05: var(--gray-50);
  --light-fill-06: var(--blue-80);
  --light-fill-07: var(--blue-90);
  --light-fill-interactive-loud: var(--blue-60);
  --light-fill-interactive-loud-hover: var(--blue-50);
  --light-fill-interactive-silent: var(--blue-10);
  --light-fill-interactive-silent-hover: var(--blue-20);
  --light-fill-positive-loud: var(--green-50);
  --light-fill-positive-silent: var(--green-10);
  --light-fill-negative-loud: var(--red-50);
  --light-fill-negative-silent: var(--red-10);
  --light-fill-attention-loud: var(--yellow-60);

  // outlines
  --light-outline-extra-silent: var(--gray-10);
  --light-outline-silent: var(--gray-20);
  --light-outline-medium: var(--gray-30);
  --light-outline-loud: var(--gray-40);
  --light-outline-extra-loud: var(--black);
  --light-outline-interactive-loud: var(--blue-60);
  --light-outline-interactive-silent: var(--blue-30);
  --light-outline-positive-loud: var(--green-50);
  --light-outline-positive-silent: var(--green-20);
  --light-outline-negative-loud: var(--red-50);
  --light-outline-negative-silent: var(--red-20);
  --light-outline-overlay: #{rgba($black, .06)};

  // opacity
  --light-opacity-overlay: #{rgba($black, .56)};
  --light-opacity-primary-8: #{rgba($gray-100, .08)};
  --light-opacity-primary-12: #{rgba($gray-100, .12)};
  --light-opacity-primary-24: #{rgba($gray-100, .24)};
  --light-opacity-primary-32: #{rgba($gray-100, .32)};
  --light-opacity-primary-48: #{rgba($gray-100, .48)};
  --light-opacity-inverted-primary-8: #{rgba($white, .08)};
  --light-opacity-inverted-primary-12: #{rgba($white, .12)};
  --light-opacity-inverted-primary-24: #{rgba($white, .24)};
  --light-opacity-inverted-primary-32: #{rgba($white, .32)};
  --light-opacity-inverted-primary-48: #{rgba($white, .48)};

  // decorative
  --light-decorative-green: linear-gradient(90deg, #00b09b 0%, #96c93d 100%);
  --light-decorative-orange: linear-gradient(90deg, #ee5682 0%, #e88b5a 100%);
  --light-decorative-red: linear-gradient(90deg, #cb356b 0%, #bd3f32 100%);
  --light-decorative-pink: linear-gradient(90deg, #ea1ee4 0%, #961ede 100%);
  --light-decorative-violet: linear-gradient(270deg, #a41edf 0%, #3e1dd9 100%);

  // shadows
  --light-shadow-s-silent: 0 1px 2px rgb(0 0 0 / .04);
  --light-shadow-s-medium: 0 1px 2px rgb(0 0 0 / .06);
  --light-shadow-s-loud: 0 1px 2px rgb(0 0 0 / .16);
  --dark-shadow-m-silent-top: 0 -2px 6px rgb(0 0 0 / .1);
  --light-shadow-m-silent: 0 2px 6px rgb(0 0 0 / .1);
  --light-shadow-m-medium: 0 2px 6px rgb(0 0 0 / .16);
  --light-shadow-m-loud: 0 2px 6px rgb(0 0 0 / .32);
  --light-shadow-xl-silent: 0 8px 24px rgb(0 0 0 / .12);
  --light-shadow-xl-medium: 0 12px 24px rgb(0 0 0 / .2);
  --light-shadow-xl-loud: 0 12px 24px rgb(0 0 0 / .28);

  // filters
  --light-fill-blur: var(--light-opacity-primary-48);
  --light-filter-blur: blur(80px);
}

:root,
:host {
  // col spacings
  --col-spacing-mobile: 16px;
  --col-spacing-tablet: 24px;
  --col-spacing-laptop: 24px;
  --col-spacing-desktop: 32px;

  // row spaings
  --row-spacing-mobile: 32px;
  --row-spacing-tablet: 32px;
  --row-spacing-laptop: 48px;
  --row-spacing-desktop: 80px;

  // outer paddings
  --outer-padding-mobile: 20px;
  --outer-padding-tablet: 24px;
  --outer-padding-laptop: 32px;
  --outer-padding-desktop: 32px;

  // section paddings
  --section-padding-mobile: 80px;
  --section-padding-tablet: 80px;
  --section-padding-laptop: 100px;
  --section-padding-desktop: 100px;

  // spacing
  --spacing-3xs: 2px;
  --spacing-2xs: 4px;
  --spacing-xs: 8px;
  --spacing-s: 12px;
  --spacing-m: 16px;
  --spacing-l: 20px;
  --spacing-xl: 24px;
  --spacing-2xl: 32px;
  --spacing-3xl: 48px;
  --spacing-4xl: 64px;
  --spacing-6xl: 80px;
  --spacing-7xl: 128px;
}
